import React, { createContext, useState } from "react";

const defaultState = {
  theme: "dark",
  toggleTheme: () => {},
};

export const ThemeContext = createContext(defaultState);

export const ThemeContextProvider = ({ children }) => {
  const initialState = {
    theme: "dark",
    toggleTheme: () => {
      setTheme((prev) => ({
        ...prev,
        theme: prev.theme === "light" ? "dark" : "light",
      }));
    },
  };
  const [theme, setTheme] = useState(initialState);

  return (
    <ThemeContext.Provider value={theme}>
      <main>{children}</main>
    </ThemeContext.Provider>
  );
};
