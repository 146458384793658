import React, { useState } from "react"
// import { UserProvider } from "./src/firebase/provider"
import { ThemeContextProvider } from "./src/utils/store"


import "./src/styles/layout.scss"



export const wrapRootElement = ({ element }) => {
  return (
    <ThemeContextProvider>
        {element}
    </ThemeContextProvider>
  )
}
